import { useContext, useEffect, useState } from "react";
import { StepIndicator } from "../../stepIndicator";
import avatarImage from '../../../assets/avatar.svg'
import whatsappImage from '../../../assets/whatsApp.png'
import clickSignImage from '../../../assets/clicksign.png'
import whatsAppImage from '../../../assets/whatsapp.svg'
import congratulationsImage from '../../../assets/congratulations.png'
import { StepContext } from "../../context";
import { OperationError } from "../../operationError";
import './styles.css'
import { formatCurrency } from "../../../utils/formatCurrency";
import { transformValue } from "../../../utils/transformValue";
import { awaitTime, isOfficeHour } from "../../../utils/timing";
import Axios from 'axios'

export function Finish() {
  const {
    operationSuccess,
    setVerifySteps,
    setCardLimit,
    setProposal,
    setValueProposal,
    setProgressIndicator,
    setRegisterSteps,
    setStep,
    getStatus,
    setGetStatus,
    valueProposal,
    proposalToken,
    HandleSteps } = useContext(StepContext)

  const [signatureConfirmed, setSignatureConfirmed] = useState<boolean>(false)

  useEffect(() => {

    (async () => {
      if (getStatus === true) {
        await awaitTime(5)
        // setGetStatus(false)
        getProposalStatus()
      }
    })()

  }, [getStatus])

  useEffect(() => {
    getProposalStatus()
  }, [])


  const getProposalStatus = async () => {
    await Axios.get(`${process.env.REACT_APP_ONLINE_API}/proposals`, {
      headers: {
        'Content-Type': 'application/json',
        'x-token': proposalToken,
      }
    }).then(({ data }) => {
      if (data.proposal.sign_status === "signed" && data.proposal.proposal_status === 'aproved') {
        setSignatureConfirmed(true)
        HandleSteps(7)
        setValueProposal(data.proposal.net_amount)
      } else {
        setGetStatus(true)
      }
    })
  }

  useEffect(() => {
    HandleSteps(6)
  }, [])


  const exit = () => {
    setVerifySteps(1)
    setCardLimit('0')
    setProposal({})
    setValueProposal('0')
    setProgressIndicator(1)
    setRegisterSteps(1)
    setStep(0)
  }

  useEffect(() => {

    (async () => {
      if (signatureConfirmed) {
        // await awaitTime(5)
        // window.scrollTo({
        //   top: document.body.scrollHeight * 0.4,
        //   left: 0,
        //   behavior: 'smooth'
        // })
      }

    })()

  }, [signatureConfirmed])

  const render = () => {
    switch (operationSuccess) {
      case true:
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StepIndicator />
            <div className="containerSMS">
              <img src={clickSignImage} alt="clickSignImage" className="clickSignImage" />
              <p>
                Agora você receberá uma <strong>mensagem da CLICKSIGN em seu <i>WhatsApp.</i></strong>
                <br />
                Para finalizar a contratação basta clicar no link enviado em seu whatsapp através da CLICKSING e assinar eletronicamente o seu contrato.
              </p>
              <img src={whatsappImage} alt="emailImage" onClick={() => sendWhatsApp()} className="WhatsAppImage" />
            </div></div>
        )
      case false:
        return (
          <div className="containerFail">
            <OperationError />
            <div className="title">
              <p>Operação indisponível</p>
            </div>
            <div className="description">
              <p>Que pena! <br /> Infelizmente seu empréstimo não foi aprovado dessa vez. <br />
                <strong>Verifique se o atendimento do seu cartão foi informado sobre esta compra.</strong>
              </p>

              <p>Lhe aguardamos em outra oportunidade</p>
            </div>
            <div className="containerButtons">
              <button onClick={exit} className="exitButton">Sair</button>
            </div>

          </div>
        )
    }
  }

  const sendWhatsApp = () => {
    return window.open(`https://api.whatsapp.com/send?phone=551142000444`, '_blank')
  }

  return (
    <>
      {!signatureConfirmed ? render() :
        <div className="containerSuccessFull">
          {/* <img src={avatarImage} /> */}
          <div className="congratulations">
            <p><strong>Parabéns!</strong>
            </p>
            <br />
            <br />
            <p style={{ fontSize: '3rem' }}>
              O seu empréstimo foi aprovado!
            </p>
            <img src={congratulationsImage} alt="congratulationsImage" />

          </div>
          <div className="alerts">
            <div className="content">
              <p><strong>Obs 1:</strong> Aparecerá  <strong><u style={{ color: 'red' }}>FACILGPS</u></strong><br /> nas faturas do seu cartão de crédito.</p>
              <p><strong>Obs 2:</strong> <strong>{isOfficeHour() ? "Em 1 hora útil" : "As 09 horas do próximo dia útil"}</strong> receberá em sua conta bancária o valor de <strong>{formatCurrency(valueProposal as unknown as number)}</strong>.</p>

              <p><strong>Obs 3:</strong> O seu contrato <strong>CCB - Cédula de crédito bancário -</strong> foi
                enviado para <strong>seu e-mail</strong> (favor verificar a caixa de spam)</p>
            </div>

          </div>
        </div>
      }
    </>
  )
}