export async function awaitTime(time: number) {
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, time * 1000)
  })
}



export function isOfficeHour(): boolean {
  let holidays = [
    // 📅 Feriados de 2025
    '01/01/2025', // Ano Novo
    '18/04/2025', // Sexta-Feira da Paixão (feriado móvel)
    '21/04/2025', // Dia de Tiradentes
    '01/05/2025', // Dia do Trabalhador
    '19/06/2025', // Corpus Christi (feriado móvel)
    '07/09/2025', // Independência do Brasil
    '12/10/2025', // Dia de Nossa Senhora Aparecida
    '02/11/2025', // Dia de Finados
    '15/11/2025', // Proclamação da República
    '20/11/2025', // Dia da Consciência Negra
    '25/12/2025', // Natal

    // 📅 Feriados de 2026
    '01/01/2026', // Ano Novo
    '03/04/2026', // Sexta-Feira da Paixão (feriado móvel)
    '21/04/2026', // Dia de Tiradentes
    '01/05/2026', // Dia do Trabalhador
    '04/06/2026', // Corpus Christi (feriado móvel)
    '07/09/2026', // Independência do Brasil
    '12/10/2026', // Dia de Nossa Senhora Aparecida
    '02/11/2026', // Dia de Finados
    '15/11/2026', // Proclamação da República
    '20/11/2026', // Dia da Consciência Negra
    '25/12/2026', // Natal
  ]

  const days = {
    0: 'Domingo',
    1: 'Segunda',
    2: 'Terça',
    3: 'Quarta',
    4: 'Quinta',
    5: 'Sexta',
    6: 'Sábado',
  }

  const date = new Date()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()
  const rightDate = new Date(year, month, day)
  console.log(date.toLocaleString())
  if (rightDate.getDay() === 6 || rightDate.getDay() === 0) {
    return false
  } else if (holidays.includes(rightDate.toLocaleString().split(',')[0])) {
    return false
  } else {
    if (hours === 16 && minutes < 20) {
      return true
    }
    else if (hours > 15 && hours < 23) {
      return false
    } else if (hours === 23 && minutes < 50) {
      return false
    } else {
      return true
    }
  }
}