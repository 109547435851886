import { useContext, useEffect, useRef, useState } from "react";

import { GridContainer } from './styles'
import Verify from "../steps/verify";
import { StepContext } from '../context'
import { Register } from "../steps/register";
import { Idwall } from "../steps/faceId";
import { PagWay } from "../steps/checkout";
import { Finish } from "../steps/finish";
import { Account } from "../steps/account"
import './styles.css'
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../loading";
import Axios from 'axios'
import { awaitTime } from "../../utils/timing";
import { CheckoutChargeAddress, CheckoutInterface } from "../@interfaces";
import { Maintenance } from "../maintenance";
import ComputerAccess from "../computerAccess";
import { formatinputs } from "../../utils/formatCpf";
import { DraggableButton } from "react-draggable-button";
import WhatsAppImage from '../../assets/whatsAppWhite.svg'
import { useSnackbar } from "notistack";
import { io } from "socket.io-client";
import { Dialog } from "@mui/material";
export function Home({ proposal = false }: { proposal?: boolean }) {
    const navigate = useNavigate()
    const { linkID, userId } = useParams()
    const { step, setLinkId, loading, setLoading, setStep, idwallSteps, setIdwallSteps, setGetStatus,
        setProposalToken, setProgressIndicator, setDisabledProgresIndicator, setDefaultUserID,
        checkoutProposal, setCheckoutProposal, setHref, setOperationSuccess, proposalToken, setCardLimit,
        setProposalNumber, proposalNumber, setName, setValueProposal, setRegisterSteps, setProposalAmounts, setCheckoutSteps, setCPF,
        setAssertiva, setOwnerTyping, setChallengeId, progressIndicator, setOpenModalConfirmation, setOpenAccountSuspect, setBackProposal,
        setOpenModalContactReason } = useContext(StepContext)
    const containerRef = useRef<HTMLDivElement>(null)
    const { enqueueSnackbar } = useSnackbar()
    const stepNames = [
        'Verificação',
        'Cadastro',
        'Verificação dos documentos',
        'Dados do cartão',
        'Finalizado!'
    ]

    const [openDialogCancel, setOpenDialogCancel] = useState(false)
    const [openDialogPaused, setOpenDialogPaused] = useState(false)

    const errorPage = false

    useEffect(() => {
        (async () => {
            if (errorPage) {

                await awaitTime(2)
                setStep(5)
                setLoading(false)
            } else {
                checkDevice()
                /* eslint-disable */
                history.pushState(null, document.title, location.href);
                window.addEventListener('popstate', () => {
                    return history.pushState(null, document.title, location.href);
                });
                if (linkID) {
                    setLinkId(linkID)
                    await Axios.get(`${process.env.REACT_APP_ONLINE_API}/marketing/${linkID}`).then(({ data }) => {
                        setPixel(data.keys.pixel.key)
                    }).catch(() => {
                        setPixel(process.env.REACT_APP_DEFAULT_PIXEL_CODE as string)
                    })
                } else {
                    const session = sessionStorage.getItem('key-facebook-pixel')
                    if (session) {
                        setPixel(session)
                    } else {
                        setPixel(process.env.REACT_APP_DEFAULT_PIXEL_CODE as string)
                    }
                }

                if (userId) {
                    setDefaultUserID(userId)
                }

                if (proposal) {
                    setLoading(true)
                    setDisabledProgresIndicator(true)
                    const url = document.location.href
                    const token = url.split('/?t=')[1].split('&i')[0]
                    await awaitTime(1)
                    sessionStorage.clear()
                    await awaitTime(1)
                    setProposalToken(token)
                } else {
                    await awaitTime(1.1)
                    setLoading(false)
                }
            }
        })()
    }, [])

    useEffect(() => {
        if (proposalToken !== '') {
            getProposal(proposalToken as string)
        }
    }, [proposalToken])

    useEffect(() => {
        if (proposalNumber) {
            const socket = io(process.env.REACT_APP_ONLINE_WS as string);

            socket.emit("sign", proposalNumber);

            socket.on(proposalNumber.toString(), (...args) => {
                if (args[0] === "cancel") {
                    setOpenDialogCancel(true)
                }

                else if (args[0] === "pause") {
                    setOpenDialogPaused(true)
                }

                else if (args[0] === "unpause") {
                    sessionStorage.clear()
                    /* @ts-ignore */
                    location.reload(true)
                }
                else if (args[0] === "confirm-whatsapp") {
                    setCheckoutSteps(4)
                    setStep(3)
                    setProgressIndicator(3)
                }
            });
        }
    }, [proposalNumber])



    async function checkDevice() {
        const tcStep = sessionStorage.getItem('tc-step')
        if (tcStep === '6') sessionStorage.removeItem('tc-step')
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)
            || navigator.userAgent.match(/Tablet/i)
            || navigator.userAgent.match(/Mobile/i)
        ) {
            return
        }
        else {
            setHref(window.location.href)
            await awaitTime(2.3)
            setStep(6)
        }
    }

    const moneyMask = (value: string) => {
        const options = { minimumFractionDigits: 2 }
        const result = new Intl.NumberFormat('pt-BR', options).format(
            parseFloat(value)
        )

        return 'R$ ' + result
    }

    const getProposal = async (token: string) => {
        if (errorPage) {
            await awaitTime(2) // para manutenção
            setStep(5) // para manutenção
            setLoading(false)
            return
        } else {
            await Axios.get(`${process.env.REACT_APP_ONLINE_API}/proposals`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-token': token,
                }
            }).then(async ({ data }) => {
                setBackProposal(data.proposal)
                // setStep(5) // para manutenção
                // return
                console.log(data)
                // setCardLimit(moneyMask(data.proposal.credit_card_limit))
                setLinkId(data.proposal.link_id)
                Axios.get(`${process.env.REACT_APP_ONLINE_API}/marketing/${data.proposal.link_id}`).then(({ data }) => {
                    setPixel(data.keys.pixel.key)
                }).catch(() => {
                    setPixel(process.env.REACT_APP_DEFAULT_PIXEL_CODE as string)
                })
                setProposalNumber(data.proposal.id)
                setName(data.client.name)
                setProposalAmounts({
                    parcels: data.proposal.parcels,
                    total: data.proposal.gross_amount,
                    value_parcels: data.proposal.gross_amount / data.proposal.parcels
                })
                setCPF(data.client.cpf)
                setValueProposal(moneyMask(data.proposal.net_amount))
                const chargeAddress: CheckoutChargeAddress = {}
                chargeAddress.cep = data.client.address.cep
                chargeAddress.city = data.client.address.city
                chargeAddress.district = data.client.address.district
                chargeAddress.number = data.client.address.number
                chargeAddress.street = data.client.address.street
                chargeAddress.state = data.client.address.state

                const newData: CheckoutInterface = {
                    checkout: {
                        chargeAddress: chargeAddress,
                        clientCpf: data.client.cpf,
                    }
                }
                setCheckoutProposal(newData)
                setOwnerTyping(data.proposal.owner_typing ? true : false)
                // verifica se a proposta foi pausada
                if (data.proposal?.paused === true) {
                    setOpenDialogPaused(true)
                }
                if (data.proposal.proposal_status === "preValidationCard") {
                    if (!data.proposal.integrations?.whatsAppMessages?.checkoutSended) {
                        setProgressIndicator(2)
                        setRegisterSteps(2)
                        setStep(1)
                        setOpenModalConfirmation(true)
                    } else if (!data.proposal.integrations?.contactReasonAnswered && data?.proposal?.owner_typing) {
                        setProgressIndicator(2)
                        setRegisterSteps(2)
                        setStep(1)
                        setOpenModalContactReason(true)
                    } else {
                        await awaitTime(1.2)
                        setProgressIndicator(3)
                        setStep(3)
                        if (data.proposal.checkout_status === "challenge") {
                            await awaitTime(0.1)
                            setChallengeId(data.proposal.integrations.acantoTransaction.id)
                            setCheckoutSteps(6)
                            setGetStatus(true)
                        } else {
                            setCheckoutSteps(4)
                        }
                    }
                }
                else if (data.proposal.proposal_status === "face_id") {
                    setAssertiva(data.proposal.integrations?.assertiva?.link ?? '')
                    await awaitTime(1.2)
                    setProgressIndicator(5)
                    setStep(2)
                    await awaitTime(0.1)
                    if (data.proposal.faceid_status === "waiting") {
                        setGetStatus(true)
                        setIdwallSteps(3)
                    }
                    else {
                        await awaitTime(1.2)
                        setIdwallSteps(1)
                    }
                }
                else if (data.proposal.proposal_status === "signature") {
                    setStep(4)
                    setProgressIndicator(6)
                    setGetStatus(true)
                    setOperationSuccess(true)
                }

                else if (data.proposal.proposal_status === "aproved") {
                    setStep(4)
                    setGetStatus(true)
                }

                else if (data.proposal.proposal_status === "transfeera") {
                    await awaitTime(1.2)
                    setProgressIndicator(4)
                    if (data.proposal?.integrations?.account_suspected_fraud) {
                        setOpenAccountSuspect(true)
                    }
                    setStep(7)
                }

                else {
                    await awaitTime(1.2)
                    setDisabledProgresIndicator(false)
                    sessionStorage.clear()
                    return window.open(`https://emprestimo.ayude.com.br/${data.proposal.link_id}`)
                }

            }).catch(() => {
                enqueueSnackbar(<p>Link inválido.<br />Acesse o link enviado para seu E-mail ou WhatsApp para continuar sua proposta.</p>, {
                    variant: 'warning',
                })
            }).finally(() => {
                setLoading(false)
                checkDevice()
            })
        }

    }

    function render() {
        switch (step) {
            case 0:
                return <Verify />

            case 1:
                return <Register />

            case 2:
                return <Idwall />

            case 3:
                return <PagWay />

            case 4:
                return <Finish />

            case 5:
                return <Maintenance />

            case 6:
                return <ComputerAccess />

            case 7:
                return <Account />

            default:
                break;
        }
    }

    const setPixel = (pixel: string) => {
        let set = false

        const head = document.getElementsByTagName('head')
        const script = head[0].getElementsByTagName('script')
        for (let item of script) {
            if (item.childNodes.length > 0) {
                if (item.childNodes[0].textContent?.includes(`fbq('init', '${pixel}')`)) {
                    set = false
                } else {
                    set = true
                }
            }
        }

        if (set === true) {
            const s = document.createElement('script')
            const ns = document.createElement('noscript')
            const img = document.createElement("img")

            img.setAttribute("height", "1")
            img.setAttribute("width", "1")
            img.setAttribute("style", "display:none")
            img.setAttribute("src", `https://www.facebook.com/tr?id=${pixel}&ev=PageView&noscript=1`)

            s.append(`!function (f, b, e, v, n, t, s) {
          if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ?
              n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
          n.queue = []; t = b.createElement(e); t.async = !0;
          t.src = v; s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
          'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${pixel}');
        fbq('track', 'PageView');`)
            ns.appendChild(img)
            head[0].appendChild(s)
            head[0].appendChild(ns)
            sessionStorage.setItem('key-facebook-pixel', pixel)
        }

    }

    const sendToWhatsApp = () => {
        window.open("https://wa.me/+5512935019799")
    }
    const resetProposal = () => {
        sessionStorage.clear()
        /* @ts-ignore */
        location.reload(true) // eslint-disable-line
    }

    return (
        <GridContainer ref={containerRef} container>
            <Dialog open={openDialogCancel}>
                <div className="divCancel">
                    <p>Atenção!</p>
                    <p>A proposta foi cancelada, não podemos dar continuidade.</p>
                    <p>Qualquer dúvida entre em contato com o nosso suporte.</p>
                    <button
                        onClick={() => resetProposal()}
                        className="buttonContinue"
                    >Entendi</button>
                </div>
            </Dialog>
            <Dialog open={openDialogPaused}>
                <div className="divCancel">
                    <p>Aguarde um momento</p>
                    <p>Estamos analisando sua proposta</p>
                </div>
            </Dialog>
            <Loading active={loading} />
            {step >= 2 && step < 5 && step !== 3 && <DraggableButton
                containerRef={containerRef}
                backgroundColor="#4fec67"
                borderMargin={10}
                borderRadius={35}
                height={70}
                width={70}
                device="mobile"
                initialBottom={20}
                initialRight={20}
                zIndex={1000}
                onClick={sendToWhatsApp}
                children={<div className="box">
                    <p>Atendimento</p>
                    <p className='option'>Opção 1</p>
                    <img src={WhatsAppImage} alt="Logo WhatsApp" /></div>}
                animationTimig={1.5}
                resizeTaxOnMove={0}
            />}
            {render()}
            {/* <div className="mark">
        <img src={circleOne} />
        <img src={circleTwo} />
      </div> */}
        </GridContainer>

    )
}